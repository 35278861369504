import { Picture, Text, ButtonV2 } from '@components/ui'
import { appendRefParameter } from '@lib/utils/tools'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import HomeTitle from '@components/home/HomeTitle/HomeTitle'
import BannerLink from '@components/common/BannerLink'

import cn from 'classnames'
import s from 'templates/Pages/HomeCharger/HomeCharger.module.css'

const Index = ({ data, pageHandle, id, gtmRef, versionB }) => {
  return (
    <>
      <div className="layer">
        <div className="content">
          <HomeTitle
            data={data}
            pageHandle={pageHandle}
            id={id}
            playAni={true}
          ></HomeTitle>
        </div>
      </div>
      <div className="layer l:p-0">
        <div className="content">
          {data?.list && (
            <div
              className={cn(
                s.cardBox,
                'flex w-full flex-wrap gap-[16px] l:flex-nowrap l:gap-[12px] l:overflow-auto l:px-[24px]'
              )}
            >
              {data?.list?.map((jItem, jIndex) => (
                <CardItem
                  key={jIndex + jItem?.tit}
                  item={jItem}
                  data={data}
                  pageHandle={pageHandle}
                  index={jIndex}
                  gtmRef={gtmRef}
                  versionB={versionB}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const CardItem = ({ item, data, index, gtmRef, versionB }) => {
  const { setRelativeLink } = useRelativeLink()
  const link = appendRefParameter({
    url: setRelativeLink({ link: item?.buttonLink }),
    refValue: gtmRef ? gtmRef + '_' + (index + 1) : pageHandle,
  })
  return (
    <div
      className={cn(
        s.cardActive,
        'relative w-[calc(50%-8px)] cursor-pointer l:w-[313px] l:flex-none'
      )}
    >
      <div className="relative w-full overflow-hidden">
        {item?.image && (
          <Picture
            source={
              versionB
                ? `${item?.image_b || item?.image}, ${
                    item?.imageMob_b ||
                    item?.image_b ||
                    item?.imageMob ||
                    item?.image
                  } 1023`
                : `${item?.image}, ${item?.imageMob || item?.image} 1023`
            }
            className={cn(
              'aspect-h-[480] aspect-w-[632] w-full l:aspect-h-[460] l:aspect-w-[313]',
              s.cardImage
            )}
            imgClassName="w-full h-full object-contain"
          ></Picture>
        )}
        <div
          className="absolute left-0 top-0 h-full w-full"
          style={{
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.80) 100%)',
          }}
        ></div>
      </div>
      <div
        className={cn(
          'absolute bottom-0 left-0 box-border w-full p-[32px] leading-[1.4] text-[#fff] xl:p-[16px]'
        )}
      >
        {item?.preTit && (
          <Text
            className="mb-[4px] text-[16px] font-[500]"
            html={versionB ? item?.preTit_b || item?.preTit : item?.preTit}
          ></Text>
        )}
        {item?.tit && (
          <Text
            className="text-[40px] font-[700] xl:text-[20px]"
            html={versionB ? item?.tit_b || item?.tit : item?.tit}
            variant="sectionSubHeading"
          ></Text>
        )}
        {item?.desc && (
          <Text
            className="mt-[4px] text-[20px] font-[500] xl:text-[16px]"
            html={versionB ? item?.desc_b || item?.desc : item?.desc}
          ></Text>
        )}
        {item?.buttonLink && (
          <div
            className={cn(
              s.cardButton,
              'relative z-[4] h-0 overflow-hidden transition-all duration-[0.4s] l:h-auto'
            )}
          >
            <ButtonV2 variant={item?.type} className="mt-[24px] xl:mt-[16px]">
              <a
                dangerouslySetInnerHTML={{ __html: data?.buttonTxt }}
                href={link}
              />
            </ButtonV2>
          </div>
        )}
      </div>
      <BannerLink to={link}></BannerLink>
    </div>
  )
}

export default Index
